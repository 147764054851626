'use-client';
import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import type { SkinWPhotoProps } from '../SkinWPhoto';
import type { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/RoundPhoto.scss';
import BasicWPhotoSkin from './BasicWPhotoSkin';

const RoundPhotoSkin: React.FC<
  Omit<BaseWPhotoSkinProps, 'skinsStyle'>
> = props => <BasicWPhotoSkin {...props} skinsStyle={skinsStyles} />;

const RoundPhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={RoundPhotoSkin} />
);

export default RoundPhoto;
